@import './variables';

:root {
  --primary-color: #5034c4;
  --primary-text-color: #000;
  --secondary-text-color: #fff;
  --light-purple: #e9e6fd;
}

@each $key, $value in $props {
  .#{$key} {
    &-primary {
      #{$value}: var(--primary-color) !important;

      &-text {
        #{$value}: var(--primary-text-color) !important;
      }
    }

    &-secondary {
      &-text {
        #{$value}: var(--secondary-text-color) !important;
      }
    }

    &-light-purple {
      #{$value}: var(--light-purple);
    }
  }
}
