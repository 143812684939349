.input {
  &:focus {
    outline: none;
  }

  &--waitlist {
    padding: 20px 24px;
    border-radius: 8px 0 0 8px;
    background: var(--light-purple);
    color: var(--primary-color);

    &::placeholder {
      color: #5134c47a;
    }

    @media screen and (max-width: 768px) {
      padding: 12px 16px;
      font-size: 14px;

      &::placeholder {
        color: #5134c47a;
      }
    }
  }
}
