.button {
  padding: 12px 30.5px;
  border-radius: 8px;
  transition: 0.3s all ease-out;
  position: relative;
  overflow: hidden;

  &-primary {
    background: var(--primary-color);
    color: var(--secondary-text-color);
  }

  &-faded {
    background: var(--light-purple);
    color: var(--primary-color);
  }

  &::after {
    content: '';
    position: absolute;
    width: 20%;
    height: 100%;
    transform: skew(30deg);
    background: rgba(205, 205, 205, 0.182);
    top: 0;
    left: -30%;
    transition: 0.3s all ease-out;
  }

  &:hover {
    // &:not(:active) {
    //   transform: scale(1.05);
    //   font-weight: bold;
    // }

    &::after {
      left: 125%;
    }
  }

  &:disabled {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      transform: skew(0deg);
      background: rgba(255, 255, 255, 0.685);
    }
  }
}
