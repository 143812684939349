@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'styles/index';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: 'rgba(255, 255, 255, 0)';
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'altone_b';
  src: local('altone-bold'), url('./assets/fonts/Altone-Bold.ttf') format('woff2');
  font-display: swap; 
}

@font-face {
  font-family: 'altone_sb';
  src: local('altone-semibold'), url('./assets/fonts/Altone-SemiBold.ttf') format('woff2');
  font-display: swap; 
}

@font-face {
  font-family: 'altone_m';
  src: local('altone-medium'), url('./assets/fonts/Altone-Medium.ttf') format('woff2');
  font-display: swap; 
}

@font-face {
  font-family: 'altone_r';
  src: local('altone-regular'), url('./assets/fonts/Altone-Regular.ttf') format('woff2');
  font-display: swap; 
}

@font-face {
	font-family: 'dmsans_r';
	src: local('DMSans'), url('./assets/fonts/DMSans-Regular.ttf') format('woff2');
	font-display: swap; 
}

@font-face {
	font-family: 'dmsans_m';
	src: local('DMSans-medium'), url('./assets/fonts/DMSans-Medium.ttf') format('woff2');
	font-display: swap; 
}

@font-face {
	font-family: 'dmsans_b';
	src: local('DMSans-bold'), url('./assets/fonts/DMSans-Bold.ttf') format('woff2');
	font-display: swap; 
}
@font-face {
  font-family: 'inter';
  src: local('Inter'), url(./assets/fonts/Inter-Black.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_thin';
  src: local('Inter-Thin'), url(./assets/fonts/Inter-Thin.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_extraLight';
  src: local('Inter-ExtraLight'), url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_light';
  src: local('Inter-Light'), url(./assets/fonts/Inter-Light.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_regular';
  src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_medium';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_semibold';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_bold';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
  font-display: swap; 
}
@font-face {
  font-family: 'inter_extraBold';
  src: local('Inter-ExtraBold'), url(./assets/fonts/Inter-ExtraBold.ttf) format('truetype');
  font-display: swap; 
}
.container{
  max-width: 1260px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
}
.earnings {
  background-image: url('./assets/images/bg-earnings.png');
  mix-blend-mode: hard-light;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.background-footer-top {
  background: linear-gradient(to bottom, white 50%, #1D2939 50%,)
}

@media screen and (max-width: 600px) {
  .container{
    padding-left: 16px;
    padding-right: 16px;
  }
}

iframe {
  min-height: 100vh;
}