.stagger__in {
  $delay: 0.3;
  @for $i from 1 through 12 {
    &--#{$i} {
      position: relative;
      animation: fade-in-from-top 1s #{$delay}s both cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-fill-mode: backwards;
    }
    $delay: $delay + 0.15;
  }
}

@keyframes fade-in-from-top {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.98);
    position: relative;
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
    position: relative;
  }
}

@keyframes slide-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-in {
  animation: fade-in 1s cubic-bezier(0.34, 1.56, 0.64, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-logo {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
