.home {
  &-wrapper {
    height: calc(100vh - 80px);
    overflow: scroll;
    padding: 78px 0 0 4.6%;
    color: var(--secondary-text-color);

    @media screen and (max-width: 640px) {
      padding: 64px 6.4% 30px;
      height: calc(100vh - 80px);
    }
  }

  &__landing-img {
    position: relative;
    animation: slide-from-bottom 1.5s 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) both;
  }

  &-faded-logo {
    animation: fade-in-logo 1.5s 1s cubic-bezier(0.34, 1.56, 0.64, 1) both;
  }
}
